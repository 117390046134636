import React from "react";
import queryString from "query-string";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import SEO from "@src/components/SEO";

const HomeConsent: React.FC = () => {
  useRedirectHandler(() => {
    const parsedLocation = queryString.parse(window?.location?.search || "");
    const { parentID = "unknown", source } = parsedLocation;

    return `https://home.classdojo.com/#/home-consent?parentID=${parentID}${source ? `&source=${source}` : ""}`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};

export default HomeConsent;
